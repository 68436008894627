import {
    ChoiceWidget,
    Contents,
    DialogueWidget,
    FormHintExplanation,
    HandWritingV2Widget,
    HandWritingWidget,
    HintExplanation,
    ImageWidget,
    InputWidget,
    MultipleChoiceWidget,
    TextWidget, ThreeDimensionalImageWidget,
    VideoWidget,
    Widget,
    WidgetType,
} from "../models/contents.ts";
import {isContents, isQuestions, UrlHelper} from "../util/url-helper.ts";
import {Questions} from "../models/Questions.ts";

export const normalizeWidget = (newWidget: Widget, contents: Contents | Questions): Widget => {
    function resetId(id: string, contents: Contents | Questions) {
        const ids = id.split("_")
        let suffix = ""
        if (ids.length == 1) {
            suffix = ids[0]
        } else {
            suffix = ids[1]
        }
        let prefix = ""
        if (isContents(contents)) {
            prefix = "c" + contents.grade + String(contents.unit).padStart(2, "0") + String(contents.lesson).padStart(2, "0")
        } else if (isQuestions(contents)) {
            prefix = "q" + contents.baseKnowledgeTagId
        } else {
            prefix = "e"
        }
        return `${prefix}_${suffix}`;
    }

    newWidget.id = resetId(newWidget.id, contents)
    var type = newWidget.type
    switch (newWidget.type) {
        case WidgetType.Dialogue:
            newWidget = setParamForDialogueWidget(newWidget as DialogueWidget, contents)
            break;
        case WidgetType.Image:
            newWidget = setParamForImageWidget(newWidget as ImageWidget, contents)
            break;
        case WidgetType.Input:
            newWidget = setParamForInputWidget(newWidget as InputWidget, contents)
            break;
        case WidgetType.Video:
            newWidget = setParamForVideoWidget(newWidget as VideoWidget, contents)
            break;
        case WidgetType.Choice:
            newWidget = setParamForChoiceWidget(newWidget as ChoiceWidget, contents)
            break;
        case WidgetType.MultipleChoice:
            newWidget = setParamForMultipleChoiceWidget(newWidget as MultipleChoiceWidget, contents)
            break;
        case WidgetType.HandWriting:
            newWidget = setParamForHandWritingWidget(newWidget as HandWritingWidget, contents)
            break;
        case WidgetType.HandWritingV2:
            newWidget = setParamForHandWritingV2Widget(newWidget as HandWritingV2Widget, contents)
            type = WidgetType.HandWriting
            break;
        case WidgetType.Text:
            newWidget = setParamForTextWidget(newWidget as TextWidget, contents)
            break;
        case WidgetType.ThreeDimensionalImage:
            newWidget = setParamForThreeDimensionalImageWidget(newWidget as ThreeDimensionalImageWidget, contents)
            break;
    }
    // @ts-ignore
    newWidget[type] = newWidget.parameters
    return newWidget
}

function setParamForDialogueWidget(widget: DialogueWidget, contents: Contents | Questions): DialogueWidget {
    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        icon_url: UrlHelper.normalizeUrl(widget.form?.icon_url, contents),
        audio_url: UrlHelper.normalizeUrl(widget.form?.audio_url, contents),
        voice_id: widget.form?.audio_url ? undefined : (widget.form?.voice_id ? widget.form.voice_id : undefined),
    })
    return newWidget
}

function setParamForTextWidget(widget: TextWidget, contents: Contents | Questions): TextWidget {
    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        audio_url: UrlHelper.normalizeUrl(widget.form?.audio_url, contents),
        voice_id: widget.form?.audio_url ? undefined : (widget.form?.voice_id ? widget.form.voice_id : undefined),
        background_url: UrlHelper.normalizeUrl(widget.form?.background_url, contents),
        padding: widget.form?.padding ? widget.form.padding : 0,
    })
    return newWidget
}

function setParamForImageWidget(widget: ImageWidget, contents: Contents | Questions): ImageWidget {
    const form = widget.form
    if (!form) {
        return widget
    }
    const ratio = form.display.w / form.original.w

    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        url: UrlHelper.normalizeUrl(widget.form?.url, contents),
        w: Math.floor(((widget.form?.original?.w ?? 0) * ratio)),
        h: Math.floor(((widget.form?.original?.h ?? 0) * ratio)),
        text: form.text ? form.text : undefined,
        voice_id: form.voice_id ? form.voice_id : undefined,
    })
    return newWidget
}

function setParamForInputWidget(widget: InputWidget, contents: Contents | Questions): InputWidget {
    const form = widget.form
    if (form === undefined) {
        return widget
    }

    switch (form.template) {
        case "EqPartial":
            return setParamForInputWidgetEqPartial(form as InputWidget.FormEqPartial, widget, contents)
        case "EqFull":
            return setParamForInputWidgetEqFull(form as InputWidget.FormEqFull, widget, contents)
        case "Manual":
        default:
            return setParamForInputWidgetManual(form as InputWidget.FormManual, widget, contents)
    }
}

function setParamForInputWidgetManual(form: InputWidget.FormManual, widget: InputWidget, contents: Contents | Questions): InputWidget {
    const ratio = form.display.w / form.original.w
    const newWidget = {...widget}

    function createTextBox(i: InputWidget.FormTextBox, defaultMaxLength: number | null, answerForMultiple: string | null): InputWidget.TextBox {
        let length = i.answer.length
        // lengthを同じanswer nameのものでmaxが最大のものに合わせる
        // ただし、異なる長さが場合はさらに+1する。
        // https://mathmaji.slack.com/archives/C059SHYQLGH/p1701234781027069
        if (defaultMaxLength != null) {
            length = defaultMaxLength
        } else if (i.answer_name) {
            const sameNames = form.input.filter(a => i.answer_name === a.answer_name)
            length = Math.max(...sameNames.map(a => a.answer.length))
            if (sameNames.some(a => a.answer.length != length)) {
                length += 1
            }
        }

        let font_size_ratio = i.font_size_ratio ?? 100
        if (font_size_ratio <= 1) {
            font_size_ratio = 100
        }
        font_size_ratio = font_size_ratio / 100

        return {
            x: i.x * ratio,
            y: i.y * ratio,
            w: i.relative_w * ratio,
            h: i.relative_h * ratio,
            font_size: i.relative_h * ratio * (35 / 45) * font_size_ratio,
            type: i.type,
            answer: (answerForMultiple ?? i.answer) || undefined,
            answer_name: i.answer_name || undefined,
            max_characters: i.max_characters ? i.max_characters : length,
            align: i.align,
        } as InputWidget.TextBox
    }

    let image_url = `q_${String(form.no).padStart(2, '0')}.${form.extension}`
    if (form.set_image_url) {
        image_url = form.image_url ?? image_url
    }

    let textBoxes: InputWidget.TextBox[] = []
    if (form.is_multiple_answers && form.multiple_answers != null) {
        const answers = form.multiple_answers!.split("\n").map((s) => s.split(","))[0]
        const answerTextLengthList = form.multiple_answers!.split("\n").flatMap((s) => s.split(",")).map((s) => s.length)
        const multipleAnswerMaxLength = Math.max(...answerTextLengthList)
        form!.input.forEach((i, idx) => {
            textBoxes.push(createTextBox(
              i,
              multipleAnswerMaxLength,
              answers.length > idx ? answers[idx] : null,
            ))
        })
    } else {
        textBoxes = form!.input.map((i) => createTextBox(i, null, null))
    }

    newWidget.parameters = Object.assign({}, {
        ...widget.parameters,
        image_url: UrlHelper.normalizeUrl(
            image_url,
            contents,
        ),
        w: form.display.w,
        h: form.original.h * ratio,
        text_boxes: textBoxes,
        text: form.text ? form.text : undefined,
        voice_id: form.voice_id ? form.voice_id : undefined,
        voice_text: form.voice_text ? form.voice_text : undefined,
        no_icon: form.no_icon,
        is_markdown: form.is_markdown,
        allow_reduction: form.allow_reduction,
        additional_texts: form.additional_texts?.map((t) => ({
            x: t.x * ratio,
            y: t.y * ratio,
            font_size: t.font_size * ratio,
            text: t.text,
            align: t.align,
        })),
        hint: formToParamForHintExplanation(form.hint, contents),
        explanation: formToParamForHintExplanation(form.explanation, contents),
        multiple_answers: stringsToParamForMultipleAnswers(form.multiple_answers),
    })
    return newWidget
}

function helper(answer: InputWidget.EqAnswer, answers: InputWidget.EqAnswer[]): Omit<InputWidget.TextBox, "x" | "y" | "w" | "h"> {
    let length = answer.answer.length
    let actualLength = length
    // lengthを同じanswer nameのものでmaxが最大のものに合わせる
    // ただし、異なる長さが場合はさらに+1する。
    // https://mathmaji.slack.com/archives/C059SHYQLGH/p1701234781027069
    if (answer.answer_name) {
        const sameNames = answers.filter(a => answer.answer_name === a.answer_name)
        length = Math.max(...sameNames.map(a => a.answer.length))
        actualLength = length
        if (sameNames.some(a => a.answer.length != length)) {
            length += 1
        }
    }

    return {
        "font_size": 40 * (actualLength >= 3 ? 0.7 : 1),
        "type": "general",
        max_characters: length,
        "answer": answer.answer,
        "answer_name": answer.answer_name,
    }
}

function formToParamForHintExplanation(form: FormHintExplanation | undefined, contents: Contents | Questions): HintExplanation | undefined {
    if (!form || (!form.url && !form.text)) {
        return undefined
    }
    const ratio = form.display.w / form.original.w
    return {
        image: form.url ? {
            url: UrlHelper.normalizeUrl(form.url, contents),
            w: form.original.w * ratio,
            h: form.original.h * ratio,
        } : undefined,
        text: form.text || undefined,
        voice_id: form.voice_id,
        is_markdown: form.is_markdown,
        voice_text: form.voice_text,
    }
}

function stringsToParamForMultipleAnswers(strings: string | undefined): InputWidget.MultipleAnswer[] | undefined {
    if (!strings) {
        return undefined
    }
    return strings?.split("\n").map((s) => {
        return {
            answers: s.split(","),
        }
    }) ?? []
}

function setParamForInputWidgetEqPartial(form: InputWidget.FormEqPartial, widget: InputWidget, contents: Contents | Questions): InputWidget {
    const answers = [form.answer1, form.answer2, form.answer3]
    return {
        ...widget,
        parameters: {
            image_url: UrlHelper.normalizeUrl('/common/EqPartial.jpg',
                contents,
            ),
            voice_id: form.text ? "teacher" : undefined,
            voice_text: form.voice_text ? form.voice_text : undefined,
            "text": form.text,
            "w": 320,
            "h": 97,
            no_icon: form.no_icon,
            is_markdown: form.is_markdown,
            text_boxes: [
                {
                    "x": 1,
                    "y": 41,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer1, answers),
                },
                {
                    "x": 71,
                    "y": 40,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer2, answers),
                },
                {
                    "x": 144,
                    "y": 41,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer3, answers),
                }
            ],
            additional_texts: [],
            hint: formToParamForHintExplanation(form.hint, contents),
            explanation: formToParamForHintExplanation(form.explanation, contents),
        },
    };
}

function setParamForInputWidgetEqFull(form: InputWidget.FormEqFull, widget: InputWidget, contents: Contents | Questions): InputWidget {
    const answers = [form.answer1, form.answer2, form.answer3, form.answer4, form.answer5]
    return {
        ...widget,
        parameters: {
            image_url: UrlHelper.normalizeUrl('/common/EqFull.jpg',
                contents,
            ),
            text: form.text || "Enter the math sentence.",
            voice_id: "teacher",
            voice_text: form.voice_text ? form.voice_text : undefined,
            "w": 320,
            "h": 97,
            no_icon: form.no_icon,
            is_markdown: form.is_markdown,
            text_boxes: [
                {
                    "x": 0,
                    "y": 41,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer1, answers),
                },
                {
                    "x": 65,
                    "y": 40,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer2, answers),
                },
                {
                    "x": 132,
                    "y": 41,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer3, answers),
                },
                {
                    "x": 199,
                    "y": 40,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer4, answers),
                },
                {
                    "x": 265,
                    "y": 41,
                    "w": 56,
                    "h": 56,
                    ...helper(form.answer5, answers),
                }
            ],
            additional_texts: [],
            hint: formToParamForHintExplanation(form.hint, contents),
            explanation: formToParamForHintExplanation(form.explanation, contents),
        },
    };
}

function setParamForVideoWidget(widget: VideoWidget, contents: Contents | Questions): VideoWidget {
    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        url: UrlHelper.normalizeUrl(widget.form?.url, contents),
    })
    return newWidget
}


function setParamForChoiceWidget(widget: ChoiceWidget, contents: Contents | Questions): ChoiceWidget {
    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        image_urls: widget.form?.image_urls
            ?.filter((url) => url !== '')
            ?.map(
                (url) => UrlHelper.normalizeUrl(url, contents),
            ),
    })
    return newWidget;
}

function setParamForMultipleChoiceWidget(widget: MultipleChoiceWidget, contents: Contents | Questions): MultipleChoiceWidget {
    const form = widget.form
    if (!form || !widget.parameters) {
        return widget
    }
    const newWidget = {...widget}
    const ratio = (form.display && form.original && form.original.w)
        ? (form.display.w / form.original.w)
        : 0
    newWidget.parameters = {
        ...widget.parameters!,
        images: form.images.map((image) => {
            const ratio = image.display.w / image.original.w
            return {
                url_default: UrlHelper.normalizeUrl(image.url_default, contents),
                url_selected: UrlHelper.normalizeUrl(image.url_selected, contents),
                w: Math.floor(image.original.w * ratio),
                h: Math.floor(image.original.h * ratio),
            }
        }),
        answers: form.images
            .map((image, idx) => {
                if (!image.is_answer) {
                    return undefined
                }
                return idx
            })
            .filter((idx) => idx !== undefined) as number[],
        text: form.text ? form.text : undefined,
        image: {
            url: UrlHelper.normalizeUrl(form.image_url, contents),
            w: Math.floor(((widget.form?.original?.w ?? 0) * ratio)),
            h: Math.floor(((widget.form?.original?.h ?? 0) * ratio)),
        },
        voice_id: form.voice_id ? form.voice_id : undefined,
        voice_text: form.voice_text ? form.voice_text : undefined,
        no_icon: form.no_icon,
        is_markdown: form.is_markdown,
        direction: form.direction,
        hint: formToParamForHintExplanation(form.hint, contents),
        explanation: formToParamForHintExplanation(form.explanation, contents),
    }
    return newWidget;
}

function setParamForHandWritingWidget(newWidget1: HandWritingWidget, contents: Contents | Questions) {
    const newWidget = {...newWidget1}
    const form = newWidget1.form
    if (form === undefined) {
        return newWidget
    }
    const ratio = form.display.w / form.original.w
    newWidget.parameters = {
        align: form.align,
        note_h: form.note_h,
        type: form.type,
        image_url: UrlHelper.normalizeUrl(form.image_url, contents),
        max_characters: form.answer.length ?? undefined,
        image_w: Math.floor(form.original.w * ratio),
        image_h: Math.floor(form.original.h * ratio),
        answer: form.answer,
        hint: formToParamForHintExplanation(form.hint, contents),
        explanation: formToParamForHintExplanation(form.explanation, contents),
        text: form.text ? form.text : undefined,
        voice_id: form.voice_id ? form.voice_id : undefined,
        no_icon: form.no_icon,
        is_markdown: form.is_markdown,
    }
    return newWidget
}

function setParamForHandWritingV2Widget(newWidget1: HandWritingV2Widget, contents: Contents | Questions) {
    const newWidget = {...newWidget1}
    const form = newWidget1.form
    if (form === undefined) {
        return newWidget
    }
    const ratio = form.display.w / form.original.w
    newWidget.parameters = {
        align: form.align,
        note_h: form.note_h,
        inputs: form.inputs.map((input) => {
            return {
                answer: input.answer,
                max_characters: input.answer.length ?? undefined,
                type: input.type,
                suffix: input.suffix,
            }
        }),
        image_url: UrlHelper.normalizeUrl(form.image_url, contents),
        image_w: Math.floor(form.original.w * ratio),
        image_h: Math.floor(form.original.h * ratio),
        hint: formToParamForHintExplanation(form.hint, contents),
        explanation: formToParamForHintExplanation(form.explanation, contents),
        text: form.text ? form.text : undefined,
        voice_id: form.voice_id ? form.voice_id : undefined,
        no_icon: form.no_icon,
        is_markdown: form.is_markdown,
    }
    return newWidget
}

function setParamForThreeDimensionalImageWidget(widget: ThreeDimensionalImageWidget, contents: Contents | Questions): ThreeDimensionalImageWidget {
    const newWidget = {...widget}
    newWidget.parameters = Object.assign({}, widget.form, {
        h: widget.form?.h ? widget.form.h : 0,
        url: UrlHelper.normalizeUrl(widget.form?.url, contents),
    })
    return newWidget
}
