import {useContext} from "react";
import {ContentContext} from "../lessons-generator/lessons-generator.tsx";
import {UrlHelper} from "../../util/url-helper.ts";

export function ModelViewer(props: {
  url: string,
  h: number,
  bg_color: string,
}) {
  const content = useContext(ContentContext)
  const modelSrc = UrlHelper.normalizeUrl(props.url, content)
  return <model-viewer src={modelSrc}
    alt="A 3D model"
    auto-rotate
    camera-controls
    style={{width: "100%", height: `${props.h}px`, background: `${props.bg_color}`}}
  />
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        src: string;
        alt: string;
        'auto-rotate'?: boolean;
        'camera-controls'?: boolean;
        style?: React.CSSProperties
      };
    }
  }
}
