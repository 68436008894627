import {createContext, useEffect, useState} from "react";
import {Contents, Widget, WidgetType} from "../../models/contents.ts";
import LessonsAppBar from "./lessons-app-bar.tsx";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Container,
    Fab,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import AddWidget from "../misc/add-widget.tsx";
import LessonsWidgetForm from "./lessons-widget-form.tsx";
import {ArrowDownward, ArrowUpward, FileCopy} from "@mui/icons-material";
import DeleteButton from "../misc/delete-widget-button.tsx";
import {normalizeWidget} from "../../common/widget.ts";
import {TransitionGroup} from "react-transition-group";
import {cloneDeep} from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoveWidget from "../misc/move-widget.tsx";
import {Questions} from "../../models/Questions.ts";
import {generateWidgetId, ShuffleId} from "../misc/shuffle-id.tsx";
import BulkDelete from "../misc/bulk-delete.tsx";
import SelectCheckbox from "../misc/select-checkbox.tsx";
import SelectAll from "../misc/select-all.tsx";
import axios from "axios";
import {TemplatesContextProvider} from "../../context/template.tsx";
import CreateTemplateButton from "../misc/create-template-button.tsx";
import UseTemplateButton from "../misc/use-template-button.tsx";
import {ExplorerContextProvider} from "../../context/explorer.tsx";
import {ExplorerPanel} from "../file-explorer/explorer-panel.tsx";
import {WidgetConverter} from "../widget-converter/widget-converter.tsx";

function generateDefaultContents(): Contents {
    return {
        grade: 'kindergarten',
        unit: 0,
        lesson: 0,
        lessonVersion: 1,
        widgets: [
            {
                id: generateWidgetId(),
                type: WidgetType.Title,
            }
        ],
    }
}

function LessonsAddWidget(props: {
    index: number,
    addWidget: (index: number, type: WidgetType) => void,
}) {
    return (
        <AddWidget index={props.index} addWidget={props.addWidget} targetTypes={[
            WidgetType.Image, WidgetType.Dialogue, WidgetType.Text, WidgetType.Input, WidgetType.Pause, WidgetType.MultipleChoice, WidgetType.HandWriting, WidgetType.HandWritingV2, WidgetType.Video, WidgetType.Title, WidgetType.Category, WidgetType.Divider, WidgetType.ThreeDimensionalImage,
        ]}/>
    )
}

export const ContentContext = createContext<Contents | Questions>({} as Contents)

export function LessonsGenerator() {
    const [contents, setContents] = useState<Contents>(() => {
        const contentsUrl = new URLSearchParams(location.search).get('ct')
        if (contentsUrl) {
            axios
                .get(contentsUrl)
                .then((res) => {
                    setContents(res.data as Contents)
                    console.log(`Successfully loaded from URL (${contentsUrl}).`)
                })
                .catch((err) => {
                    console.error(`Failed to load from URL (${contentsUrl}).`, err)
                })
            const contents = generateDefaultContents()
            contents.widgets = []
            return contents
        }

        const loaded = localStorage.getItem("contents")
        if (loaded) {
            return JSON.parse(loaded) as Contents
        }
        return generateDefaultContents()
    })
    useEffect(() => {
        localStorage.setItem("contents", JSON.stringify(contents))
    })

    // keeps track of selectedWidgets
    // replaces "checked" and "isChecked" in each Checkbox
    const [selectedWidgetsSet, setSelectedWidgetsSet] = useState(new Set<string>());

    // when handleSelect is invoked when box is checked, it adds the widget id to the Selected set
    // when invoked when box is unchecked, we remove it from the set
    const handleSelect = (checked: boolean, id: string) => {
        if (checked) {
            setSelectedWidgetsSet(prevSet => new Set(prevSet).add(id))
        }
        // delete function returns bool, so we return the Set itself
        else {
            setSelectedWidgetsSet(prevSet => {
                const newSet = new Set(prevSet);
                newSet.delete(id);
                return newSet;
            })
        }
    }

    const handleSelectAll = (allChecked: boolean) => {
        if (allChecked) {
            const newSet = new Set<string>
            for (let i = 0; i < contents.widgets.length; i++) {
                newSet.add(contents.widgets[i].id)
            }
            setSelectedWidgetsSet(newSet)
        }
        else {
            setSelectedWidgetsSet(new Set<string>)
        }
    }

    const addWidget = (index: number, widgetType: WidgetType) => {
        const newContents = {...contents}
        newContents.widgets.splice(index, 0, {
            id: generateWidgetId(),
            type: widgetType,
        })
        setContents(newContents)
    }
    const changeWidget = (value: Widget) => {
        const newContents = {...contents}
        newContents.widgets[newContents.widgets.findIndex((w) => w.id === value.id)] =
            normalizeWidget(value, contents);
        setContents(newContents)
    }

    const deleteWidget = (index: number) => {
        const newContents = {...contents}
        newContents.widgets.splice(index, 1)
        setContents(newContents)
    }

    const deleteWidgets = () => {
        // deep copy of contents
        let newContents = {...contents}
        // filter for the widgets in newQuestions.widgets list that includes widgets NOT in the selectedWidgetsSet
        // in other words, filter out the widgets in the selectedWidgetsSet from newQuestions.widgets
        newContents.widgets = newContents.widgets.filter(widget => !selectedWidgetsSet.has(widget.id))
        // setQuestions state with newQuestions
        setContents(newContents)
    }

    const normalizeContents = (): Contents => {
        const newContents = {...contents}
        for (let i = 0; i < newContents.widgets.length; i++) {
            let newWidget = newContents.widgets[i]
            newContents.widgets[i] = normalizeWidget(newWidget, contents)
        }
        setContents(newContents)
        return newContents
    }

    const moveWidget = (index: number, direction: number) => {
        const newContents = {...contents}
        const tmp = newContents.widgets[index]
        newContents.widgets[index] = newContents.widgets[index + direction]
        newContents.widgets[index + direction] = tmp
        setContents(newContents)
    }
    const fixedMoveWidget = (from: number, to: number) => {
        if (from === to) return
        if (from < 0 || from >= contents.widgets.length) return
        const newContents = {...contents}
        const tmp = newContents.widgets[from]
        newContents.widgets.splice(from, 1)
        newContents.widgets.splice(to, 0, tmp)
        setContents(newContents)
    }

    const copyWidget = (index: number) => {
        const newContents = {...contents}
        const newWidget = cloneDeep(newContents.widgets[index])
        newWidget.id = Math.random().toString(32).substring(2)
        normalizeWidget(newWidget, newContents)
        newContents.widgets.splice(index + 1, 0, newWidget)
        setContents(newContents)
    }

    const clearWidgets = () => {
        setContents(generateDefaultContents())
    }

    return (
        <ContentContext.Provider value={contents}>
            <Box sx={{bgcolor: "#F5F5F5", pb: 2}}>
            <ExplorerContextProvider contents={contents} setContents={setContents}>
            <ExplorerPanel />
            <LessonsAppBar
                load={(newContents: Contents) => {
                    newContents.widgets.forEach((widget) => {
                        if (!widget.id) {
                            widget.id = Math.random().toString(32).substring(2)
                        }
                    })
                    setContents(newContents)
                }}
                clear={clearWidgets}
                normalizeContents={normalizeContents}
            />
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{my: 2}}>
                    <Grid item xs={2}>
                        <TextField label="lessonId" value={String(contents.lessonId) || ""}
                                   sx={{bgcolor: "white"}}
                                   onChange={
                                       (event) => {
                                           const newContents = {...contents}
                                           newContents.lessonId = event.target.value
                                           setContents(newContents)
                                       }
                                   }/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="version" type="number" value={String(contents.lessonVersion) || "1"}
                                   sx={{bgcolor: "white"}}
                                   onChange={
                                       (event) => {
                                           const newValue = parseInt(event.target.value)
                                           if (newValue) {
                                               const newContents = {...contents}
                                               newContents.lessonVersion = parseInt(event.target.value)
                                               setContents(newContents)
                                           }
                                       }
                                   }/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="grade" value={String(contents.grade) || "K"}
                                   sx={{bgcolor: "white"}}
                                   onChange={
                                       (event) => {
                                           const newContents = {...contents}
                                           newContents.grade = event.target.value
                                           setContents(newContents)
                                       }
                                   }/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="unit" type="number" value={String(contents.unit) || "0"}
                                   sx={{bgcolor: "white"}}
                                   onChange={
                                       (event) => {
                                           const newValue = parseInt(event.target.value)
                                           if (newValue) {
                                               const newContents = {...contents}
                                               newContents.unit = parseInt(event.target.value)
                                               setContents(newContents)
                                           }
                                       }
                                   }/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="lesson" type="number" value={String(contents.lesson) || "0"}
                                   sx={{bgcolor: "white"}}
                                   onChange={
                                       (event) => {
                                           const newValue = parseInt(event.target.value)
                                           if (newValue) {
                                               const newContents = {...contents}
                                               newContents.lesson = parseInt(event.target.value)
                                               setContents(newContents)
                                           }
                                       }
                                   }/>
                    </Grid>
                    <Grid item xs={2}>
                        <ShuffleId contents={contents} setContents={(contents) => setContents(contents as Contents)}/>
                    </Grid>
                    <Grid item xs={2}>
                        <BulkDelete deleteWidgets={deleteWidgets}/>
                    </Grid>
                    <Grid item xs={2}>
                        <WidgetConverter deleteWidgets={deleteWidgets} getSelectedWidgets={
                            () => {
                                return contents.widgets.filter(widget => selectedWidgetsSet.has(widget.id))
                            }
                        }/>
                    </Grid>
                    <Grid item xs={2}>
                        {/* if num of selected widgets === num of total widgets, all are checked */}
                        <SelectAll id="select-all" allChecked={selectedWidgetsSet.size === contents.widgets.length} handleSelectAll={handleSelectAll}/>
                    </Grid>
                </Grid>
                {
                    contents.widgets.length === 0
                        ? <LessonsAddWidget index={0} addWidget={addWidget}/>
                        : <></>
                }
                <TransitionGroup>
                    {
                        contents.widgets.map((widget, idx) => (
                            <Collapse key={widget.id}>
                                <Card key={widget.id} sx={{my: 2, p: 2,}} variant="outlined">
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="center">
                                            <LessonsWidgetForm widget={widget} contents={contents} onChange={changeWidget}/>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Box sx={{pr: 2}}><Typography color="gray">{ idx }</Typography></Box>
                                        <IconButton size="small" color="primary" disabled={idx === 0} onClick={() => {
                                            moveWidget(idx, -1)
                                        }}><ArrowUpward/></IconButton>
                                        <IconButton size="small" color="primary"
                                                    disabled={idx === contents.widgets.length - 1} onClick={() => {
                                            moveWidget(idx, 1)
                                        }}><ArrowDownward/></IconButton>
                                        <DeleteButton idx={idx} onDelete={deleteWidget}/>
                                        <IconButton size="small" color="primary" onClick={() => {
                                            copyWidget(idx)
                                        }}><FileCopy /></IconButton>
                                        <TemplatesContextProvider contents={contents} setContents={setContents}>
                                            <CreateTemplateButton widget={widget}/>
                                            <UseTemplateButton idx={idx + 1}/>
                                        </TemplatesContextProvider>
                                        <LessonsAddWidget index={idx + 1} addWidget={addWidget}/>
                                        <MoveWidget moveWidget={(to: number) => {
                                            fixedMoveWidget(idx, to)
                                        }}/>
                                        <Box sx={{pl: 2, pr: 2}}><Typography color="gray">ID: { widget.id }</Typography></Box>
                                        <SelectCheckbox id ={widget.id} checked={selectedWidgetsSet.has(widget.id)} handleSelect={handleSelect} setSelectedWidgetsSet={setSelectedWidgetsSet}/>
                                    </CardActions>
                                </Card>
                            </Collapse>
                        ))
                    }
                </TransitionGroup>
            </Container>
            </ExplorerContextProvider>
            <Fab color="secondary" aria-label="up" size="small" sx={{position: "fixed"}} style={{
                "bottom": "1rem",
                "right": "2rem"
            }} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}} >
                <KeyboardArrowUpIcon/>
            </Fab>
            </Box>
        </ContentContext.Provider>
    );
}

