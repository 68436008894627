import {ThreeDimensionalImageWidget, Widget} from "../../models/contents.ts";
import {Grid, TextField} from "@mui/material";
import React from "react";
import {ModelViewer} from "../misc/model-viewer.tsx";

function ThreeDimensionalImageWidgetForm(props: {
  value: ThreeDimensionalImageWidget
  onChange: (value: Widget) => void
}) {
  if (props.value.form === undefined) {
    props.value.form = {
      bg_color: props.value.parameters?.bg_color || "#FFFFFF",
      h: props.value.parameters?.h || 0,
      url: props.value.parameters?.url || ""
    }
    props.onChange(props.value)
    return <></>
  }
  return (
    <>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="type"
          defaultValue={props.value.type}
          disabled={true}
        />
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="height"
          defaultValue={props.value.form?.h}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (Number(event.target.value) < 0) {
              return
            }
            const newValue = {...props.value}
            newValue.form!.h = Number(event.target.value)
            props.onChange(newValue)
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="bg_color"
          defaultValue={props.value.form?.bg_color}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = {...props.value}
            newValue.form!.bg_color = event.target.value
            props.onChange(newValue)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="url"
          defaultValue={props.value.form?.url}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = {...props.value}
            newValue.form!.url = event.target.value
            props.onChange(newValue)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {props.value.form?.url &&
            <ModelViewer url={props.value.form?.url} h={props.value.form?.h} bg_color={props.value.form?.bg_color}/>}
      </Grid>
    </>
  );
}

export default ThreeDimensionalImageWidgetForm;
